@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");



* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  align-items: center;
  padding: 1vh;
  justify-content: center;
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
}